import { Injectable, Injector, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppModule } from '../app.module';
import { takeUntil } from 'rxjs/operators';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService implements OnInit, OnDestroy {
  public isLoading = new BehaviorSubject(false);
  private unsubscribe$ = new Subject();
  private spinnerService: NgxSpinnerService;
  constructor() {
   /*  const injector: Injector = AppService.injector;
    console.log(injector);
    if (injector) {
      this.spinnerService = injector.get<NgxSpinnerService>(NgxSpinnerService);
    } */
  }

  ngOnInit(): void {
    /* this.isLoading.pipe(takeUntil(this.unsubscribe$)).subscribe(t => {
      console.log('isLoadding = ' + t);
      if (t) {
        this.spinnerService.show();
      } else {
        this.spinnerService.hide();
      }
    }); */
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
