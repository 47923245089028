import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { firestore } from 'firebase';
import { Subject, of } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { firebaseIdms, FIREBASE_STRUCT, ORDER_REQUEST_EXPORT_STATUS, ORDER_STATUS } from 'src/app/app.constant';
import { Handling } from 'src/app/decorators/handling';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-order-request-export-add',
  templateUrl: './order-request-export-add.component.html',
  styleUrls: ['./order-request-export-add.component.scss'],
})
export class OrderRequestExportAddComponent implements OnInit, OnDestroy {
  uid: any;
  request: any = {};
  requests: any[] = [];
  unsubscribe$ = new Subject();
  orders: any[] = [];
  userUid: any;

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navaparam: NavParams,
    @Inject(firebaseIdms) public auth: AngularFireAuth,
    public commonService: CommonService
  ) {
    this.uid = this.navaparam.data.modal.componentProps.uid;
    this.userUid = this.auth.auth.currentUser.uid;

    this.request = {
      orders: [],
      requester_note: null,
      request_priority: 'Bình thường'
    };

    this._fetchCustomerWithOrders(this.userUid);
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getUser(uid) {
    return this.fs.doc<any>(`${FIREBASE_STRUCT.USERS_ADMIN.NODE}/${uid}`).snapshotChanges().pipe(
      map(snap => ({ uid: snap.payload.id, ...snap.payload.data() })),
      takeUntil(this.unsubscribe$)
    );
  }

  @Handling()
  private async _fetchCustomerWithOrders(userUid: any) {
    try {
      let user: any = await this.fs.collection<any>(`${FIREBASE_STRUCT.USERS.NODE}`).doc<any>(userUid).snapshotChanges().pipe(
        map(doc => {
          if (doc) { return { uid: userUid, ...doc.payload.data() }; } else { return null; }
        }),
        first()
      ).toPromise();

      if (user) {
        this.request.customer_id = user.id;
        this.request.customer_code = user.user_code;
        this.request.customer_name = user.full_name;
        this.request.orders_search = await this._getOrders(userUid).pipe(first()).toPromise();
      } else {
        throw new Error('Không tìm thấy khách hàng tương ứng. Vui lòng nhập lại');
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  private _getOrders(userUid) {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE, q => {
      return q.where('order_user_uid', '==', userUid)
        .where('order_status_key', '==', ORDER_STATUS.DATHANHTOAN.KEY);
    }).valueChanges();
  }

  getOrder(uid: any) {
    return this.fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc<any>(uid).snapshotChanges().pipe(
      switchMap(sn => {
        if (sn.payload.exists) {
          return this.fs.doc(`${FIREBASE_STRUCT.USERS.NODE}/${sn.payload.data().order_user_uid}`).valueChanges().pipe(
            map((user: any) => ({
              uid: sn.payload.id,
              ...sn.payload.data(),
              customer_name: user.full_name
            })));
        } else {
          return of(null);
        }
      }),
      first()
    ).toPromise();
  }

  @Handling()
  async onAddRequest() {
    try {
      if (!this.request.customer_id) { throw new Error(('Chưa nhập id khách hàng')); }
      if (!this.request.customer_name) { throw new Error(('ID khách hàng không đúng. Vui lòng nhập lại')); }
      if (!this.request.orders || !this.request.orders.length) { throw new Error(('Chưa nhập mã đơn hàng')); }
      if (!this.request.request_priority) { throw new Error(('Chưa chọn mức độ yêu cầu')); }

      this.requests.push({
        ...this.request,
        requester: this.auth.auth.currentUser.displayName,
        request_date: await this.commonService.getServerTime(),
        status_key: ORDER_REQUEST_EXPORT_STATUS.CHUAXULY.KEY,
        status_text: ORDER_REQUEST_EXPORT_STATUS.CHUAXULY.VALUE,
      });
      this.request = {
        orders: [],
        requester_note: null,
        request_priority: 'Bình thường'
      };
      this._fetchCustomerWithOrders(this.auth.auth.currentUser.uid);
    } catch (error) {
      throw error;
    }
  }

  onRemoveRequest(i) {
    this.requests.splice(i, 1);
  }

  @Handling()
  async onAddRequests() {
    const fs = firestore();
    const batch = fs.batch();
    try {
      let b = true;
      for (const request of this.requests) {
        b = await this.checkRequest(request);
        const _idOrders = [];
        const _codeOrders = [];
        for (const _orderUid of request.orders) {
          const __order: any = await fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(_orderUid).get().then(doc => ({ ...doc.data(), uid: doc.id }));
          _idOrders.push(__order.order_code);
          _codeOrders.push(__order.order_code_2);
        }
        const r = { ...request, idOrders: _idOrders, codeOrders: _codeOrders };
        delete r.orders_search;
        batch.set(fs.collection(`${FIREBASE_STRUCT.ORDER_REQUEST_EXPORT.NODE}`).doc(), r);
      }

      if (b) {
        await batch.commit();

      } else {
        throw new Error('Dữ liệu nhập không đúng');
      }
      this.modalCtrl.dismiss();

      return 'Thêm yêu cầu thành công';
    } catch (error) {
      throw error;
    }
  }

  async checkRequest(request) {
    if (!request.customer_id) { return false; }
    if (!request.customer_name) { return false; }
    if (!request.request_priority) { return false; }
    return true;
  }

}
