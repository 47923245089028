import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  header: string;

  constructor(
    public modal: ModalController,
    public navParams: NavParams,
    public ele: ElementRef
  ) {
    this.header = this.navParams.data.modal.componentProps.header;
  }

  ngOnInit() {
    setTimeout(() => {
      this.ele.nativeElement.focus();
    }, 0);
  }

  onDismiss(v?) {
    this.modal.dismiss(v);
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === 13) {
      this.onDismiss(true);
    }
  }
}
