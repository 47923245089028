import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { GuideComponent } from './guide/guide.component';
import { PipesModule } from '../pipes/pipes.module';
import { ConsignmentOrderAddComponent } from './consignment-order-add/consignment-order-add/consignment-order-add.component';
import { DirectiveModule } from '../directives/directive.module';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { OrderRequestExportAddComponent } from '../layout/components/order-request-export-add/order-request-export-add.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertComponent } from '../layout/components/alert/alert.component';
import { WithdrawMoneyComponent } from '../layout/components/withdraw-money/withdraw-money.component';
import { OrderRequestExportEditComponent } from '../layout/components/order-request-export-edit/order-request-export-edit.component';
import { ChatComponent } from '../layout/components/chat/chat.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmDepositComponent } from './confirm-deposit/confirm-deposit.component';
import { DeliveryBillAddComponent } from '../layout/components/delivery-bill-add/delivery-bill-add.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule,
        PipesModule,
        DirectiveModule,
        BsDatepickerModule,
        DatepickerModule,
        NgSelectModule,
        MatToolbarModule, MatIconModule, MatSidenavModule, MatInputModule, MatButtonModule, ScrollingModule, MatSnackBarModule,
        MatListModule, MatDialogModule,
    ],
    declarations: [
        ConfirmModalComponent,
        GuideComponent,
        ConfirmDepositComponent,
        ConsignmentOrderAddComponent,
        OrderRequestExportAddComponent,
        DeliveryBillAddComponent,
        OrderRequestExportEditComponent,
        AlertComponent,
        WithdrawMoneyComponent
    ],
    entryComponents: [
        ConfirmModalComponent,
        GuideComponent,
        ConfirmDepositComponent,
        ConsignmentOrderAddComponent,
        OrderRequestExportAddComponent,
        DeliveryBillAddComponent,
        OrderRequestExportEditComponent,
        AlertComponent,
        WithdrawMoneyComponent
    ],
    exports: [
        ConfirmModalComponent,
        GuideComponent,
        ConfirmDepositComponent,
        ConsignmentOrderAddComponent,
        OrderRequestExportAddComponent,
        DeliveryBillAddComponent,
        OrderRequestExportEditComponent,
        AlertComponent,
        WithdrawMoneyComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalsModule { }
