import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { firestore } from 'firebase';
import { Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DELIVERY_BILL_STATUS, FIREBASE_STRUCT, ORDER_STATUS, firebaseIdms } from 'src/app/app.constant';
import { Handling } from 'src/app/decorators/handling';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-delivery-bill-add',
  templateUrl: './delivery-bill-add.component.html',
  styleUrls: ['./delivery-bill-add.component.scss'],
})
export class DeliveryBillAddComponent implements OnInit {
  customerId;
  orders;
  deliver_info: any = {};
  customer: any;
  unsubscribe$ = new Subject();
  deliver_hour = 0;
  deliver_type = 'Giao trực tiếp';
  deliver_detail: string;

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    public navParams: NavParams,
    @Inject(firebaseIdms) public auth: AngularFireAuth,
    public commonService: CommonService
  ) {
    this.customerId = this.navParams.data.modal.componentProps.customerId;
    this.orders = this.navParams.data.modal.componentProps.orders;

    this._getCustomerById(this.customerId).then(u => {
      this.customer = u;
    });
    this.setDefaultDeliverInfo();
  }

  async ngOnInit() { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  addHourToDate(hour: number) {
    const date = new Date();
    date.setHours(date.getHours() + hour);
    return date.getTime();
  }

  onCheckDirectDeliver() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_direct_deliver = !this.deliver_info.is_direct_deliver;
    this.deliver_hour = this.deliver_info.date_direct_deliver;
    this.deliver_type = 'Giao trực tiếp';
  }

  onCheckShipInternal() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_internal = !this.deliver_info.is_ship_internal;
    this.deliver_hour = this.deliver_info.date_ship_internal;
    this.deliver_type = 'Ship nội thành';
  }

  onCheckShipExternal() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_external = !this.deliver_info.is_ship_external;
    this.deliver_hour = this.deliver_info.date_ship_external;
    this.deliver_type = 'Ship xe khách';
  }

  onCheckShipViettelFast() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_viettel_fast = !this.deliver_info.is_ship_viettel_fast;
    this.deliver_hour = this.deliver_info.date_ship_viettel_fast;
    this.deliver_type = 'Ship Viettel';
    this.deliver_detail = 'Chuyển nhanh';
  }

  onCheckShipViettelV60() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_viettel_v60 = !this.deliver_info.is_ship_viettel_v60;
    this.deliver_hour = this.deliver_info.date_ship_viettel_v60;
    this.deliver_type = 'Ship Viettel';
    this.deliver_detail = 'V60';
  }

  onCheckShipViettelNormal() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_viettel_normal = !this.deliver_info.is_ship_viettel_normal;
    this.deliver_hour = this.deliver_info.date_ship_viettel_normal;
    this.deliver_type = 'Ship Viettel';
    this.deliver_detail = 'Chuyển thường';
  }

  onCheckShipNhatTinFast() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_nhattin_fast = !this.deliver_info.is_ship_nhattin_fast;
    this.deliver_hour = this.deliver_info.date_ship_nhattin_fast;
    this.deliver_type = 'Ship Nhất Tín';
    this.deliver_detail = 'Chuyển nhanh';
  }

  onCheckShipNhatTinMes() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_nhattin_mes = !this.deliver_info.is_ship_nhattin_mes;
    this.deliver_hour = this.deliver_info.date_ship_nhattin_mes;
    this.deliver_type = 'Ship Nhất Tín';
    this.deliver_detail = 'V60';
  }

  onCheckShipNhatTinNormal() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_nhattin_normal = !this.deliver_info.is_ship_nhattin_normal;
    this.deliver_hour = this.deliver_info.date_ship_nhattin_normal;
    this.deliver_type = 'Ship Nhất Tín';
    this.deliver_detail = 'Chuyển thường';
  }

  onCheckShipMinhTrieu() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_minhtrieu = !this.deliver_info.is_ship_minhtrieu;
    this.deliver_hour = this.deliver_info.date_ship_minhtrieu;
    this.deliver_type = 'Minh Triệu';
    this.deliver_detail = 'Chuyển tàu';
  }

  onCheckShipAALExpress() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_aal_express = !this.deliver_info.is_ship_aal_express;
    this.deliver_hour = this.deliver_info.date_ship_aal_express;
    this.deliver_type = 'AAL';
    this.deliver_detail = 'Hỏa tốc';
  }

  onCheckShipAALFast() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_aal_fast = !this.deliver_info.is_ship_aal_fast;
    this.deliver_hour = this.deliver_info.date_ship_aal_fast;
    this.deliver_type = 'AAL';
    this.deliver_detail = 'Chuyển nhanh';
  }

  onCheckShipAALShip() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_aal_ship = !this.deliver_info.is_ship_aal_ship;
    this.deliver_hour = this.deliver_info.date_ship_aal_ship;
    this.deliver_type = 'AAL';
    this.deliver_detail = 'Chuyển tàu';
  }

  onCheckShipGiaBaoFast() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_giabao_fast = !this.deliver_info.is_ship_giabao_fast;
    this.deliver_hour = this.deliver_info.date_ship_giabao_fast;
    this.deliver_type = 'Gia Bảo';
    this.deliver_detail = 'Chuyển bay nhanh';
  }

  onCheckShipGiaBaoSlow() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_giabao_slow = !this.deliver_info.is_ship_giabao_slow;
    this.deliver_hour = this.deliver_info.date_ship_giabao_slow;
    this.deliver_type = 'Gia Bảo';
    this.deliver_detail = 'Chuyển bay chậm';
  }

  onCheckShipGiaBaoShip() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_giabao_ship = !this.deliver_info.is_ship_giabao_ship;
    this.deliver_hour = this.deliver_info.date_ship_giabao_ship;
    this.deliver_type = 'Gia Bảo';
    this.deliver_detail = 'Chuyển tàu';
  }

  onCheckShipOther() {
    this.setDefaultDeliverInfo();
    this.deliver_info.is_ship_other = !this.deliver_info.is_ship_other;
    this.deliver_hour = this.deliver_info.date_ship_other;
    this.deliver_type = 'Khác';
  }

  onChangeShipOther(ev: any) {
    this.deliver_info.date_ship_other = Number(ev);
    this.deliver_hour = this.deliver_info.date_ship_other;
  }


  setDefaultDeliverInfo() {
    this.deliver_info = {
      is_direct_deliver: false,
      is_ship_internal: false,
      is_ship_external: false,
      is_ship_viettel_fast: false,
      is_ship_viettel_v60: false,
      is_ship_viettel_normal: false,
      is_ship_nhattin_fast: false,
      is_ship_nhattin_mes: false,
      is_ship_nhattin_normal: false,
      is_ship_minhtrieu: false,
      is_ship_aal_express: false,
      is_ship_aal_fast: false,
      is_ship_aal_ship: false,
      is_ship_giabao_fast: false,
      is_ship_giabao_slow: false,
      is_ship_giabao_ship: false,
      is_ship_other: false,
      date_direct_deliver: 0,
      date_ship_internal: 24,
      date_ship_external: 48,
      date_ship_viettel_fast: 84,
      date_ship_viettel_v60: 108,
      date_ship_viettel_normal: 156,
      date_ship_nhattin_fast: 60,
      date_ship_nhattin_mes: 84,
      date_ship_nhattin_normal: 156,
      date_ship_minhtrieu: 180,
      date_ship_aal_express: 36,
      date_ship_aal_fast: 36,
      date_ship_aal_ship: 156,
      date_ship_giabao_fast: 60,
      date_ship_giabao_slow: 84,
      date_ship_giabao_ship: 156,
      date_ship_other: ''
    };
  }

  @Handling()
  public async onSubmit() {
    const fs = firestore();
    const batch = fs.batch();
    const currentDateTime = await this.commonService.getServerTime();

    try {
      const deliveryBillCode = await fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.DELIVERY_BILL_UID).get().then(doc => {
        return Number(doc.get('id') || 0) + 1;
      });
      const deliverInfo = { ...this.deliver_info };

      const orderCodes = [];
      const consignmentOrderCodes = [];
      const transportCodes = [];

      for (const order of this.orders) {
        delete order.existTransportNotYetRelease;
        const deliveryBillCodes: any[] = order.delivery_bill_codes || [];

        deliveryBillCodes.push(deliveryBillCode);
        orderCodes.push(order.order_code);

        for (const transport of order.transports) {
          delete transport.isTransportNotYetRelease;
          transportCodes.push(transport.transport_code);

          batch.update(fs.collection(FIREBASE_STRUCT.TRANSPORTS.NODE).doc(transport.transport_uid), {
            transport_date_release_customer: transport.transport_date_release_customer
          });

          batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.order_uid).collection('transports').doc(transport.order_transport_uid), {
            transport_date_release_customer: transport.transport_date_release_customer
          });
        }

        const order_auto_finish = order.order_auto_finish || false;
        if (order_auto_finish) {
          batch.delete(fs.collection(FIREBASE_STRUCT.ORDERS_AUTO_FINISH.NODE).doc(order.order_uid));
        }

        let delivery_info_uid = order.delivery_info_uid;
        if (!delivery_info_uid) {
          delivery_info_uid = fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.order_uid).collection('deliver_info').doc().id;
          order.delivery_info_uid = delivery_info_uid;
        } else {
          batch.delete(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.order_uid).collection('deliver_info').doc(delivery_info_uid));
        }

        batch.set(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.order_uid).collection('deliver_info').doc(delivery_info_uid), {
          is_direct_deliver: deliverInfo.is_direct_deliver,
          is_ship_internal: deliverInfo.is_ship_internal,
          is_ship_external: deliverInfo.is_ship_external,
          is_ship_viettel_fast: deliverInfo.is_ship_viettel_fast,
          is_ship_viettel_v60: deliverInfo.is_ship_viettel_v60,
          is_ship_viettel_normal: deliverInfo.is_ship_viettel_normal,
          is_ship_nhattin_fast: deliverInfo.is_ship_nhattin_fast,
          is_ship_nhattin_mes: deliverInfo.is_ship_nhattin_mes,
          is_ship_nhattin_normal: deliverInfo.is_ship_nhattin_normal,
          is_ship_minhtrieu: deliverInfo.is_ship_minhtrieu,
          is_ship_aal_express: deliverInfo.is_ship_aal_express,
          is_ship_aal_fast: deliverInfo.is_ship_aal_fast,
          is_ship_aal_ship: deliverInfo.is_ship_aal_ship,
          is_ship_giabao_fast: deliverInfo.is_ship_giabao_fast,
          is_ship_giabao_slow: deliverInfo.is_ship_giabao_slow,
          is_ship_giabao_ship: deliverInfo.is_ship_giabao_ship,
          is_ship_other: deliverInfo.is_ship_other,
          date_direct_deliver: deliverInfo.date_direct_deliver,
          date_ship_internal: deliverInfo.date_ship_internal,
          date_ship_external: deliverInfo.date_ship_external,
          date_ship_viettel_fast: deliverInfo.date_ship_viettel_fast,
          date_ship_viettel_v60: deliverInfo.date_ship_viettel_v60,
          date_ship_viettel_normal: deliverInfo.date_ship_viettel_normal,
          date_ship_nhattin_fast: deliverInfo.date_ship_nhattin_fast,
          date_ship_nhattin_mes: deliverInfo.date_ship_nhattin_mes,
          date_ship_nhattin_normal: deliverInfo.date_ship_nhattin_normal,
          date_ship_minhtrieu: deliverInfo.date_ship_minhtrieu,
          date_ship_aal_express: deliverInfo.date_ship_aal_express,
          date_ship_aal_fast: deliverInfo.date_ship_aal_fast,
          date_ship_aal_ship: deliverInfo.date_ship_aal_ship,
          date_ship_giabao_fast: deliverInfo.date_ship_giabao_fast,
          date_ship_giabao_slow: deliverInfo.date_ship_giabao_slow,
          date_ship_giabao_ship: deliverInfo.date_ship_giabao_ship,
          date_ship_other: deliverInfo.date_ship_other
        });

        batch.update(fs.collection(FIREBASE_STRUCT.ORDERS.NODE).doc(order.order_uid), {
          delivery_bill_codes: deliveryBillCodes,
          delivery_info_uid,
          order_status_key: ORDER_STATUS.DATHANHTOAN.KEY,
          order_status_text: ORDER_STATUS.DATHANHTOAN.VALUE,
          order_date_paid: currentDateTime,
          order_date_delivered: '',
          order_date_finish: '',
          order_deliver_type: this.deliver_type || '',
          order_deliver_detail: this.deliver_detail || '',
          order_auto_finish: false,
          is_finished: false
        });
      }

      batch.set(fs.collection(FIREBASE_STRUCT.DELIVERY_BILL.NODE).doc(), {
        delivery_bill_code: deliveryBillCode,
        orders: this.orders,
        order_codes: orderCodes,
        consignment_order_codes: consignmentOrderCodes,
        transport_codes: transportCodes,
        customer_uid: this.customer.uid,
        customer_id: this.customer.id,
        customer_code: this.customer.user_code,
        customer_full_name: this.customer.full_name,
        customer_phone_number: this.customer.phone,
        customer_email: this.customer.email,
        customer_receive_address: this.customer.receive_address || '',
        customer_care_emp_uid: this.customer.customer_care_emp_uid || 'not_choosed',
        customer_care_emp_full_name: this.customer.customer_care_emp_full_name || 'not_choosed',
        order_emp_uid: this.customer.order_emp_uid || 'not_choosed',
        order_emp_full_name: this.customer.order_emp_full_name || 'not_choosed',
        owned_uid_admin_users: this.customer.owned_uid_admin_users || [],
        delivery_status_key: DELIVERY_BILL_STATUS.CHUAXULY.KEY,
        delivery_status_text: DELIVERY_BILL_STATUS.CHUAXULY.VALUE,
        number_of_package: 0,
        created_at: currentDateTime,
        created_by: this.auth.auth.currentUser.displayName,
        branch_uid: this.customer.branch_uid,
        is_direct_deliver: deliverInfo.is_direct_deliver,
        deliver_hour: this.deliver_hour,
        is_from_admin: false
      });

      batch.update(fs.collection(FIREBASE_STRUCT.UID_INFO.NODE).doc(FIREBASE_STRUCT.UID_INFO.DELIVERY_BILL_UID), {
        id: deliveryBillCode
      });

      await batch.commit();
      this.modalCtrl.dismiss(true);

      return 'Tạo phiếu xuất kho thành công';
    } catch (error) {
      throw error;
    }
  }

  private _getCustomerById(userId) {
    return this.fs.collection<any>(`${FIREBASE_STRUCT.USERS.NODE}`, q => {
      return q.where('id', '==', userId);
    }).snapshotChanges().pipe(
      map(sns => {
        if (sns.length) { return { uid: sns[0].payload.doc.id, ...sns[0].payload.doc.data() }; } else { return null; }
      }),
      first()
    ).toPromise();
  }
}
