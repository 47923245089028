import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Handling } from 'src/app/decorators/handling';
import { Subject, Observable } from 'rxjs';
import { AlertController, ModalController, LoadingController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { firebaseIdms, FIREBASE_STRUCT } from 'src/app/app.constant';
import { map, first, takeUntil, switchMap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { Decimal } from 'src/app/models/app.models';

@Component({
  selector: 'app-consignment-order-add',
  templateUrl: './consignment-order-add.component.html',
  styleUrls: ['./consignment-order-add.component.scss'],
})
export class ConsignmentOrderAddComponent implements OnInit, OnDestroy {
  consignmentOrders: any[];
  authInfo: any;
  unsubscribe$ = new Subject();
  paramInssurrance: any[];
  consignmentOrderRuleCreate = '';
  consignmentOrderRuleTransportNormal = '';
  consignmentOrderRuleTransportSpecial = '';
  actionsRole: any;

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    @Inject(firebaseIdms) private firebaseIdms: AngularFireAuth,
    private fs: AngularFirestore,
    private loadingCtrl: LoadingController,
    private api: ApiServiceService
  ) {
    this._getContent('home_create_consignment_order_rule').subscribe((content: any) => {
      if (content) {
        this.consignmentOrderRuleCreate = content.content_html;
      } else {
        this.consignmentOrderRuleCreate = '';
      }
    });

    this._getContent('home_consignment_order_rule_transport_normal').subscribe((content: any) => {
      if (content) {
        this.consignmentOrderRuleTransportNormal = content.content_html;
      } else {
        this.consignmentOrderRuleTransportNormal = '';
      }
    });

    this._getContent('home_consignment_order_rule_transport_special').subscribe((content: any) => {
      if (content) {
        this.consignmentOrderRuleTransportSpecial = content.content_html;
      } else {
        this.consignmentOrderRuleTransportSpecial = '';
      }
    });

    this.getCustomerActionRole().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(actions => {
      this.actionsRole = actions;
    });
  }

  async ngOnInit() {
    this.paramInssurrance = await this.getParamInsuarance();
    this.consignmentOrders = [];
    const consignmentOrder = {
      transport_code: null,
      content: null,
      product_quantity: null,
      product_link: null,
      shop_name: null,
      require: null,
      item_value: null,
      is_insurrance: false,
      insurrance_key: this.paramInssurrance[1].value,
      insurrance_text: null,
      insurrance_fee: null,
      action: null
    };
    this.consignmentOrders.push(consignmentOrder);
    this.getUserInfo(this.firebaseIdms.auth.currentUser.uid).subscribe(u => {
      this.authInfo = u;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  getUserInfo(userUid: string) {
    return this.fs.collection(`${FIREBASE_STRUCT.USERS.NODE}`).doc<any>(userUid).valueChanges().pipe(
      switchMap(user => {
        let ob: Observable<any>;
        const vipType = user.vip.type;
        if (vipType === 1) {
          ob = this.fs.collection(FIREBASE_STRUCT.PARAMETER_VIPS_NORMAL.NODE).doc(user.vip.uid).valueChanges();
        } else {
          ob = this.fs.collection(FIREBASE_STRUCT.PARAMETER_VIPS_SPECIAL.NODE).doc(user.vip.uid).valueChanges();
        }
        return ob.pipe(
          map(vip => {
            return { ...user, uid: userUid, vip: { ...vip, type: vipType, uid: user.vip.uid } };
          })
        );
      })
    );
  }

  onAddTemp() {
    const consignmentOrder = {
      transport_code: null,
      content: null,
      product_quantity: null,
      product_link: null,
      shop_name: null,
      require: null,
      item_value: null,
      is_insurrance: false,
      insurrance_key: this.paramInssurrance[1].value,
      insurrance_text: null,
      insurrance_fee: null,
      action: null
    };
    this.consignmentOrders.push(consignmentOrder);
  }

  async onSubmit() {
    try {
      this.alertCtrl.create({
        header: 'Xác nhận tạo đơn ký gửi?',
        message: `<div style="color: initial; height: 500px; overflow: auto;">${this.consignmentOrderRuleCreate}</div>`,
        cssClass: 'enable-select',
        buttons: [
          /* {
            text: 'Bằng việc click ĐỒNG Ý là quý khách chấp nhận thực hiện theo tất cả các quy định của công ty',
            cssClass: 'text-confirm',
            handler: () => {
              return false;
            }
          }, */
          {
            text: 'Đồng ý',
            cssClass: 'ok-btn',
            handler: () => {
              this.loadingCtrl.create({
                duration: 5000,
                spinner: 'dots'
              }).then((loading) => {
                loading.present().then(async () => {
                  const check = {};
                  for (let i = 0; i < this.consignmentOrders.length; i++) {
                    this.consignmentOrders[i].transport_code = String(this.consignmentOrders[i].transport_code).trim();
                    const consignmentOrder = this.consignmentOrders[i];

                    if (!consignmentOrder.transport_code) {
                      throw new Error(`Chưa nhập mã vận đơn tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
                    }

                    if (!consignmentOrder.content) {
                      throw new Error(`Chưa nhập nội dung hàng tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
                    }

                    if (!consignmentOrder.product_quantity) {
                      throw new Error(`Chưa nhập số lượng tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
                    }

                    const isDuplicateTransportCode: any = await this.checkTransport(consignmentOrder.transport_code);
                    if (isDuplicateTransportCode.length) {
                      throw new Error(`Mã vận đơn ${consignmentOrder.transport_code} đã được nhập ở đơn hàng ${isDuplicateTransportCode.toString()}. Vui lòng nhập mã vận đơn khác`);
                    }

                    if (check[consignmentOrder.transport_code]) {
                      throw new Error(`Mã vận đơn '${consignmentOrder.transport_code}' bị trùng lăp. Vui lòng kiểm tra lại thông tin`);
                    } else {
                      check[consignmentOrder.transport_code] = true;
                    }

                    if (this.actionsRole.thaotacchonvanchuyendonkygui && consignmentOrder.is_transport_normal === undefined) {
                      throw new Error(`Chưa chọn hình thức chuyển phát tại dòng số ${i + 1}. Vui lòng kiểm tra lại.`);
                    }
                  }

                  const user = { ...this.authInfo };

                  await this.api.post('consignment-orders/createNewConsignment', { consignments: this.consignmentOrders, user_uid: user.uid });

                  this.modalCtrl.dismiss();
                  return '<div>• Tạo đơn ký gửi thành công</div>';
                }).then((msgSuccess) => {
                  this.alertCtrl.create({
                    header: 'Thông báo',
                    message: `${msgSuccess}`,
                    cssClass: 'enable-select',
                    buttons: [
                      {
                        role: 'cancel',
                        text: 'OK',
                        cssClass: 'cancel-btn'
                      }
                    ]
                  }).then(alert => {
                    alert.present();
                    loading.dismiss();
                  });
                }).catch(msgError => {
                  this.alertCtrl.create({
                    header: 'Lỗi',
                    message: `${msgError.message}`,
                    cssClass: 'enable-select',
                    buttons: [
                      {
                        role: 'cancel',
                        text: 'OK',
                        cssClass: 'ok-btn'
                      }
                    ]
                  }).then(alert => {
                    alert.present();
                    loading.dismiss();
                  });
                });
              });
            }
          },
          {
            role: 'cancel',
            cssClass: 'cancel-btn',
            text: 'Hủy'
          }
        ]
      }).then(alert => {
        alert.present();
      });
    } catch (error) {
      throw error;
    }
  }

  async checkTransport(transportCode) {
    const fs = firebase.firestore();
    const consignmentCode = await fs.collection(FIREBASE_STRUCT.CONSIGNMENT_TRANSPORT.NODE)
      .where('transport_code', '==', transportCode).get().then(actions => {
        const rs = [];
        actions.docs.forEach(doc => {
          rs.push(doc.get('consignment_code'));
        });
        return rs;
      });

    const orderCode = await fs.collection(FIREBASE_STRUCT.ORDER_TRANSPORT.NODE).where('transport_code', '==', transportCode).get().then(actions => {
      const rs = [];
      actions.docs.forEach(doc => {
        rs.push(doc.get('order_code'));
      });
      return rs;
    });

    return new Promise((resolve, reject) => {
      if (consignmentCode.length) {
        resolve(consignmentCode);
      } else if (orderCode.length) {
        resolve(orderCode);
      } else {
        resolve([]);
      }
    });

  }

  onDeleteTemp(index) {
    this.consignmentOrders.splice(index, 1);
  }

  onCheckInsuarance(consignmentOrder) {
    consignmentOrder.is_insurrance = !consignmentOrder.is_insurrance;
    if (consignmentOrder.is_insurrance) {
      const money = consignmentOrder.item_value || 0;
      consignmentOrder.insurrance_fee = consignmentOrder.is_insurrance ? new Decimal(money).mul(consignmentOrder.insurrance_key).toNumber() : 0;
    } else {
      consignmentOrder.insurrance_fee = 0;
      consignmentOrder.insurrance_key = this.paramInssurrance[1].value;
    }
  }

  onChangeItemValue(ev: any, consignmentOrder) {
    const money = Number(ev) || 0;
    consignmentOrder.insurrance_fee = consignmentOrder.is_insurrance ? new Decimal(money).mul(consignmentOrder.insurrance_key).toNumber() : 0;
  }

  onSelectInsuarance(consignmentOrder) {
    const money = consignmentOrder.item_value || 0;
    consignmentOrder.insurrance_fee = consignmentOrder.is_insurrance ? new Decimal(money).mul(consignmentOrder.insurrance_key).toNumber() : 0;
  }

  getConsignment(uid: string) {
    return this.fs.firestore.collection(FIREBASE_STRUCT.CONSIGNMENTS.NODE).doc(uid)
      .get().then(doc => ({ ...doc.data(), uid: doc.id }))
  }

  getParamInsuarance() {
    return this.fs.firestore.collection(FIREBASE_STRUCT.PARAMETER_CONSIGNMENT_ORDER_INSURRANCE_FEE.NODE)
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })))
  }

  private _getContent(docUid) {
    return this.fs.collection('PARAMETER_CONTENT').doc(docUid).valueChanges().pipe(
      takeUntil(this.unsubscribe$)
    );
  }

  public onSelectTransportClassification(index, value) {
    let headerConfirm = 'Xác nhận lựa chọn hình thức chuyển phát thường?';
    let msgConfirmSuccess = '<div>• Bạn đã lựa chọn hình thức chuyển phát thường</div>';
    if (!value) {
      headerConfirm = 'Xác nhận lựa chọn hình thức chuyển phát đặc biệt?';
      msgConfirmSuccess = '<div>• Bạn đã lựa chọn hình thức chuyển phát đặc biệt</div>';
    }
    this.alertCtrl.create({
      header: headerConfirm,
      message: `<div style="color: initial; height: 500px; overflow: auto;">${this.consignmentOrderRuleTransportNormal}</div>`,
      cssClass: 'enable-select',
      buttons: [
        {
          text: 'Đồng ý',
          cssClass: 'ok-btn',
          handler: () => {
            this.loadingCtrl.create({
              duration: 5000,
              spinner: 'dots'
            }).then((loading) => {
              loading.present().then(async () => {
                this.consignmentOrders[index].is_transport_normal = value;
                return msgConfirmSuccess;
              }).then((msgSuccess) => {
                this.alertCtrl.create({
                  header: 'Thông báo',
                  message: `${msgSuccess}`,
                  cssClass: 'enable-select',
                  buttons: [
                    {
                      role: 'cancel',
                      text: 'OK',
                      cssClass: 'cancel-btn'
                    }
                  ]
                }).then(alert => {
                  alert.present();
                  loading.dismiss();
                });
              }).catch(msgError => {
                this.alertCtrl.create({
                  header: 'Lỗi',
                  message: `${msgError.message}`,
                  cssClass: 'enable-select',
                  buttons: [
                    {
                      role: 'cancel',
                      text: 'OK',
                      cssClass: 'ok-btn'
                    }
                  ]
                }).then(alert => {
                  alert.present();
                  loading.dismiss();
                });
              });
            });
          }
        }
      ]
    }).then(alert => {
      alert.present();
    });
  }

  private _isValidLink(link) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(link);
  }

  private getCustomerActionRole() {
    return this.fs.collection('USERS_ACTION_ROLE').doc('IsH3Gk3Rk9LuC7rukXwY').valueChanges();
  }

}
