import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask.js';
import { createTextMaskInputElement } from 'text-mask-core/dist/textMaskCore';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface NumberMaskDefinition {
  prefix: string;
  suffix: string;
  includeThousandsSeparator: boolean;
  thousandsSeparatorSymbol: string;
  allowDecimal: boolean;
  decimalSymbol: string;
  decimalLimit: number;
  integerLimit: number;
  allowNegative: boolean;
  allowLeadingZeroes: boolean;
}

const defaultNumberMaskDefinition: NumberMaskDefinition = {
  prefix: '',
  suffix: ' ₫',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 13,
  allowNegative: false,
  allowLeadingZeroes: false
};

@Directive({
  selector: '[appNumberInput]',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '(change)': 'onChange($event.target.value)',
    '(input)': 'onChange($event.target.value)',
    '(blur)': 'onTouched()'
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NumberInputDirective,
    multi: true
  }]
})
export class NumberInputDirective implements ControlValueAccessor, NumberMaskDefinition, OnInit, OnChanges {
  @Input() public prefix: string;
  @Input() public suffix: string;
  @Input() public includeThousandsSeparator: boolean;
  @Input() public allowDecimal: boolean;
  @Input() public decimalLimit: number;
  @Input() public integerLimit: number;
  @Input() public allowNegative: boolean;
  @Input() public allowLeadingZeroes: boolean;

  public thousandsSeparatorSymbol: string;
  public decimalSymbol: string;

  private textMaskInputElement: any;
  private inputElement: HTMLInputElement;

  // stores the last value for comparison
  private lastValue: any;

  public onChange = (_: any) => { };
  public onTouched = () => { };

  private get numberMaskDefinition(): NumberMaskDefinition {
    return {
      prefix: this.prefix,
      suffix: this.suffix,
      includeThousandsSeparator: this.includeThousandsSeparator,
      thousandsSeparatorSymbol: this.thousandsSeparatorSymbol,
      allowDecimal: this.allowDecimal,
      decimalSymbol: this.decimalSymbol,
      decimalLimit: this.decimalLimit,
      integerLimit: this.integerLimit,
      allowNegative: this.allowNegative,
      allowLeadingZeroes: this.allowLeadingZeroes
    };
  }

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) { }

  public ngOnInit() {
    this.prefix = this.prefix !== undefined ? this.prefix : defaultNumberMaskDefinition.prefix;
    this.suffix = this.suffix !== undefined ? this.suffix : defaultNumberMaskDefinition.suffix;
    this.includeThousandsSeparator = this.includeThousandsSeparator !== undefined ? this.includeThousandsSeparator : defaultNumberMaskDefinition.includeThousandsSeparator;
    this.thousandsSeparatorSymbol = this.thousandsSeparatorSymbol !== undefined ? this.thousandsSeparatorSymbol : defaultNumberMaskDefinition.thousandsSeparatorSymbol;
    this.allowDecimal = this.allowDecimal !== undefined ? this.allowDecimal : defaultNumberMaskDefinition.allowDecimal;
    this.decimalSymbol = this.decimalSymbol !== undefined ? this.decimalSymbol : defaultNumberMaskDefinition.decimalSymbol;
    this.decimalLimit = this.decimalLimit !== undefined ? this.decimalLimit : defaultNumberMaskDefinition.decimalLimit;
    this.integerLimit = this.integerLimit !== undefined ? this.integerLimit : defaultNumberMaskDefinition.integerLimit;
    this.allowNegative = this.allowNegative !== undefined ? this.allowNegative : defaultNumberMaskDefinition.allowNegative;
    this.allowLeadingZeroes = this.allowLeadingZeroes !== undefined ? this.allowLeadingZeroes : defaultNumberMaskDefinition.allowLeadingZeroes;

    this.setupMask(true);
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.setupMask(true);
    if (this.textMaskInputElement !== undefined) {
      this.textMaskInputElement.update(this.inputElement.value);
    }
  }

  public writeValue(value: number): void {
    this.setupMask();

    const normalizedValue = value == null ? '' : this.mask(value);

    this.renderer.setProperty(this.elementRef.nativeElement, 'value', normalizedValue);

    if (this.textMaskInputElement !== undefined) {
      this.textMaskInputElement.update(normalizedValue);
      this.lastValue = normalizedValue;
    }
  }
  public registerOnChange(fn: (_: number | null) => void): void {
    this.onChange = value => {
      this.setupMask();

      if (this.textMaskInputElement !== undefined) {
        this.textMaskInputElement.update(value);

        // get the updated value
        value = this.inputElement.value;

        // check against the last value to prevent firing ngModelChange despite no changes
        if (this.lastValue !== value) {
          this.lastValue = value;
          const parsedValue = value === '' ? null : this.unmask(value);
          fn(parsedValue);
        }
      }
    };
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = () => {
      /* const parsedValue = this.lastValue === '' ? null : this.unmask(this.lastValue);
      if (isNaN(parsedValue)) {
        this.onChange('');
      } else {
        this.onChange(this.mask(parsedValue));
      }
      console.log(parsedValue); */
      fn();
    };
  }

  public setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  private setupMask(create = false) {
    const numberMask = createNumberMask({
      prefix: this.prefix,
      suffix: this.suffix,
      includeThousandsSeparator: this.includeThousandsSeparator,
      thousandsSeparatorSymbol: this.thousandsSeparatorSymbol,
      allowDecimal: this.allowDecimal,
      decimalSymbol: this.decimalSymbol,
      decimalLimit: this.decimalLimit,
      integerLimit: this.integerLimit,
      allowNegative: this.allowNegative,
      allowLeadingZeroes: this.allowLeadingZeroes
    });

    const textMaskConfig = {
      mask: numberMask,
      guide: false,
      placeholderChar: '_',
      pipe: undefined,
      keepCharPositions: false
    };

    if (!this.inputElement) {
      if (this.elementRef.nativeElement.tagName === 'INPUT') {
        this.inputElement = this.elementRef.nativeElement;
      } else {
        this.inputElement = this.elementRef.nativeElement.getElementsByTagName('INPUT')[0];
      }
    }

    if (this.inputElement && create) {
      this.textMaskInputElement = createTextMaskInputElement(Object.assign({ inputElement: this.inputElement }, textMaskConfig));
    }
  }

  private unmask(value: string): number {
    if (value === null || value === undefined || value === '') {
      return null;
    }

    let newValue = value;

    if (this.numberMaskDefinition.thousandsSeparatorSymbol) {
      newValue = newValue.split(this.numberMaskDefinition.thousandsSeparatorSymbol).join('');
    }

    if (this.numberMaskDefinition.decimalSymbol) {
      newValue = newValue.replace(this.numberMaskDefinition.decimalSymbol, '.');
    }

    if (this.numberMaskDefinition.prefix) {
      newValue = newValue.replace(this.numberMaskDefinition.prefix, '');
    }

    if (this.numberMaskDefinition.suffix) {
      newValue = newValue.replace(this.numberMaskDefinition.suffix, '');
    }

    newValue = newValue.replace('_', '');

    return newValue.length === 0 ? null : parseFloat(newValue);
  }

  private mask(value: number): string {
    let newValue = value === null ? '' : value.toString();

    if (isFinite(value)) {
      newValue = newValue.toString().replace('.', this.decimalSymbol);
    }

    return newValue;
  }

}
