import { HttpClientModule } from "@angular/common/http";
import { NgModule, NgZone, PLATFORM_ID } from "@angular/core";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Import AngularFire
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuth, AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { BsDatepickerModule, BsDatepickerConfig, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateModule } from "@ngx-translate/core";
// Spinner and Toastr
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { firebaseIdms } from "./app.constant";
import { AppService } from "./app.service";
import { CoreModule } from "./cores/core.module";
import { ModalsModule } from "./modals/modals";
import { LoaderService } from "./services/loader.service";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomParserFormatter } from "./constants/datepicker.model";
import { AngularFireDatabaseModule } from "@angular/fire/database";

export const AngularFireIdms = (platformId: Object, zone: NgZone) => {
  return new AngularFireAuth(
    environment.firebaseIdms,
    firebaseIdms,
    platformId,
    zone
  );
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    IonicModule.forRoot({ animated: false }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseBo),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    ModalsModule
  ],
  providers: [
    BsDatepickerConfig,
    AppService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LoaderService,
    {
      provide: firebaseIdms,
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFireIdms,
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    /* { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true } */
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
