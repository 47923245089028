import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, take, tap } from 'rxjs/operators';
import { firebaseIdms } from '../app.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    @Inject(firebaseIdms) private firebaseIdms: AngularFireAuth,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.firebaseIdms.user.pipe(
      take(1),
      tap(user => {
        if (!user) {
          this.router.navigate(['signin']);
        }
      }),
      map(user => !!user),
    );
  }

}
