import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../pipes/pipes.module';
import { NumberInputDirective } from './number-input.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule,
        TranslateModule,
        PipesModule
    ],
    declarations: [
        NumberInputDirective
    ],
    entryComponents: [
    ],
    exports: [
        NumberInputDirective
    ]
})
export class DirectiveModule { }
