import { Pipe, PipeTransform } from "@angular/core";
import { Decimal } from "src/app/models/app.models";

const CURRENCIES = {
  CNY: " ¥",
  VND: " ₫",
  KG: " kg",
};

@Pipe({
  name: "appCurrency",
})
export class AppCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    currerncyCode?: string,
    groupSep?: string,
    exp?: number
  ): any {
    if (value === null || value === undefined) {
      value = 0;
    }

    const CURRENCIES_CHAR = currerncyCode
      ? CURRENCIES[currerncyCode.toUpperCase()] || null
      : null;
    const GROUP_SEP = groupSep ? groupSep : ",";
    const DECIMAL_SEPARATE_CHAR = ".";

    const roundUp = (x, e?) => {
      e = e || 0;
      return new Decimal(
        Math.ceil(new Decimal(x).mul(Math.pow(10, e)).toNumber())
      )
        .div(Math.pow(10, e))
        .toNumber();
    };

    const numberWithGroupSepAndCurencyChar = (x) => {
      const numerParts = roundUp(x, exp)
        .toString()
        .split(DECIMAL_SEPARATE_CHAR);
      numerParts[0] = numerParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, GROUP_SEP);
      return numerParts.join(DECIMAL_SEPARATE_CHAR) + CURRENCIES_CHAR;
    };

    return numberWithGroupSepAndCurencyChar(value);
  }
}
